<template>
  <div class="header p-4 text-white">
    <h1 class="display-4">Grand Holidays</h1>
  </div>
  <div class="schedules container my-3">
    <div class="card shadow-sm">
      <div class="card-header bg-success text-white">
        <h2 class="h4 mb-0">Thanksgiving</h2>
      </div>
      <ul class="list-group list-group-flush">
        <li v-for="(family, year) in thanksgivingSchedule" :key="year" class="list-group-item">
          <div class="list-content col-12">
            <div class="year">{{ year }}</div>
            <div class="family">{{ family }}</div>
          </div>
        </li>
      </ul>
    </div>
    <div class="card shadow-sm">
      <div class="card-header bg-danger text-white">
        <h2 class="h4 mb-0">Christmas</h2>
      </div>
      <ul class="list-group list-group-flush">
        <li v-for="(family, year) in christmasSchedule" :key="year" class="list-group-item">
          <div class="list-content col-12">
            <div class="year">{{ year }}</div>
            <div class="family">{{ family }}</div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
const thanksgivingSchedule = {
  2021: "Grosso Seltzers",
  2022: "Grand",
  2023: "Patricks",
  2024: "Slaters",
  2025: "Grosso Gasteyers",
  2026: "Grossos",
  2027: "Grosso Sippels",
  2028: "Patricks",
  2029: "Grosso Seltzers",
  2030: "Grand"
}

const christmasSchedule = {
  2021: "Grand",
  2022: "Grosso Seltzers",
  2023: "Grosso Sippels",
  2024: "Patricks",
  2025: "Slaters",
  2026: "Grosso Gasteyers",
  2027: "Grossos",
  2028: "Grand",
  2029: "Grosso Seltzers",
  2030: "Grosso Sippels"
}

export default {
  name: 'GrandFamilyHolidays',
  computed: {
    thanksgivingSchedule () {
      return thanksgivingSchedule
    },
    christmasSchedule () {
      return christmasSchedule
    }
  },
}
</script>

<style scoped lang="scss">
.header {
  background: linear-gradient(135deg, #0f69eb, #285391);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0;

  h1 {
    font-family: 'Arial', sans-serif;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    margin: 0;
  }
}

.schedules {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;

  .card {
    border-radius: 5px;
    flex: 1;
    min-width: 45%;
  }

  .list-group-item {
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #ddd;
    display: flex;
    font-size: 1rem;
    padding: 0.3rem 0.75rem;

    .list-content {
      .year {
        font-weight: bold;
        font-size: 0.8rem;
        text-align: right;
      }

      .family {
        font-size: 1rem;
        text-align: left;
      }
    }
  }
}

</style>